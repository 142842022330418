import { CenteredSpinner } from '@vannevarlabs/ui-kit-js/components';
import { Suspense, lazy } from 'react';
import * as ReactDOM from 'react-dom/client';
import './main.css';

const App = lazy(() => import('./App'));

ReactDOM.createRoot(document.getElementById('app')!).render(
  <Suspense fallback={<CenteredSpinner />}>
    <App />
  </Suspense>
);
